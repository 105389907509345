/**
 * Created by sharma on 11/24/2015.
 */

app.factory('Player', ['$resource', function ($resource) {
    return $resource('api/player/:serial', {},
        {
            query: {
                isArray: false
            },
            get: {
                url: 'api/player/:serial/:venue',
                method: 'GET'
            },
            getMrx: {
                url: 'api/mrx/:serial/:venue',
                method: 'GET'
            },
            register: {
                url: 'api/player',
                method: 'POST'
            }
        });

}]);