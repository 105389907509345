/**
 * Created by sharma on 11/5/2015.
 */

app.controller("AuthController", ['$scope', '$auth', '$state', '$stateParams', '$timeout', 'Auth', function($scope, $auth, $state, $stateParams, $timeout, Auth)
{
    $scope.credentials = {email: '', password: ''};

    $scope.forms = {};

    $scope.user = {};

    $scope.login = function(frmLogin)
    {
        if(frmLogin.$invalid)
        {
            return;
        }

        $auth.login($scope.credentials).then(function()
            {
                $state.go('admin.dashboard');
            },
            function(rsp)
            {
                if(rsp.data && rsp.data.error)
                {
                    $scope.error = rsp.data.error;

                    $timeout(function()
                    {
                        $scope.error = false;
                    }, 5000)
                }
            });
    };

    $scope.logout = function()
    {
        $auth.logout();

        $state.go('auth.login');
    };

    $scope.sendResetLink = function()
    {
        if($scope.forms.password.$invalid)
        {
            return;
        }
        Auth.sendResetPasswordLink({email: $scope.email}, function(rsp)
        {
            if(rsp.status === "passwords.user")
            {
                $scope.error = 'The email address could not be found';

                $timeout(function()
                {
                    $scope.error = null;
                }, 5000);
            }
            else if(rsp.status === "passwords.sent")
            {
                $scope.success = 'An email was sent with instructions to reset your password.';
            }
        });
    };

    $scope.resetPassword = function()
    {
        if($scope.forms.reset.$invalid)
        {
            return;
        }

        var $post = {
            token                : $stateParams.token,
            email                : $scope.user.email,
            password             : $scope.user.password,
            password_confirmation: $scope.user.password_confirmation
        };

        Auth.resetPassword($post, function(rsp)
        {
            if(rsp.status === 'passwords.user')
            {
                $scope.error = 'User not found';
            }
            else if(rsp.status === 'passwords.token')
            {
                $scope.error = 'Invalid token';
            }
            else if(rsp.status === 'passwords.reset')
            {
                $scope.success = 'Your password has been reset. you will be redirected to the login page.';

                $timeout(function()
                {
                    $scope.success = null;
                    $state.go('auth.login');
                }, 5000);
            }

            $timeout(function()
            {
                $scope.error = null;
            }, 5000);

        }, function(rsp)
        {
            $scope.errors = rsp.data;

            $timeout(function()
            {
                $scope.errors = null;
            }, 5000);
        });
    }

}]);