/**
 * Created by sharma on 1/8/2016.
 */

app.factory('Ulm', ['$resource', function($resource)
{
    return $resource('api/ulm', {},
        {
            query: {isArray: false},
            get  : {url: 'api/ulm/search', method: 'GET', isArray: true}
        });
}]);