/**
 * Created by sharma on 11/22/2015.
 */

app.controller ("StatController", ['$scope', '$state', '$stateParams', 'Stat', function ($scope, $state, $stateParams, Stat)
{
    $scope.today = Date.now();



    $scope.itemsPerPage = 10;

    $scope.jobsCurrentPage = 1;

    $scope.loadStats = function ()
    {
        $scope.stats = Stat.get();
    };

    $scope.showJobs = function (day, ref)
    {
        var url = $state.href('admin.completed', {day : day, ref: ref});

        window.open(url,'_blank');
    };

    $scope.loadJobsFor = function ()
    {
        $scope.day = $stateParams.day;

        $scope.ref = $stateParams.ref;

        Stat.scopeFor({day: $scope.day, ref: $scope.ref}, function (rsp)
        {
            $scope.jobs = rsp;

            $scope.jobsPageChanged();
        });
    };

    $scope.jobsPageChanged = function ()
    {
        $scope.filteredJobs = $scope.jobs.slice(
            ( ($scope.jobsCurrentPage - 1) * $scope.itemsPerPage ),
            ( ( $scope.jobsCurrentPage ) * $scope.itemsPerPage ));
    }

}]);

