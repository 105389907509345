/**
 * Created by sharma on 11/5/2015.
 */

app.controller("JobController", ['$scope', '$timeout', '$interval', '$state', '$stateParams', '$filter', 'Lightbox', 'Job', 'Player', function ($scope, $timeout, $interval, $state, $stateParams, $filter, Lightbox, Job, Player) {
    $scope.forms = {};

    $scope.alerts = {};

    $scope.player = {serial: '', pairingKey: '', deviceId: ''};

    $scope.alpha_id = $stateParams.alpha_id;

    $scope.itemsPerPage = 10;

    $scope.jobsCurrentPage = 1;

    $scope.showSubmit = false;

    $scope.venue = {};

    var stop;

    // Authorize by alpha id
    $scope.authorize = function () {
        if ($scope.frmAuthJob.$valid) {
            Job.authorize({alpha_id: $scope.alpha_id}).$promise.then(function (rsp) {
                if (rsp.alpha_id) {
                    $state.go('postcode', {alpha_id: rsp.alpha_id});
                }

                $scope.frmAuthJob.$invalid = true;

            });
        }
    };

    // Validate the post code associated to the venue's alpha id
    $scope.validatePostcode = function () {
        if ($scope.frmAuthPostCode.$valid) {
            Job.validatePostcode(
                {alpha_id: $scope.alpha_id, postcode: $scope.postcode}).$promise.then(function (rsp) {
                if (rsp.postcode) {
                    $state.go('job', {alpha_id: $scope.alpha_id, postcode: rsp.postcode, f: true});
                }

                $scope.frmAuthPostCode.$invalid = true;

            });
        }
    };

    // Get a job by alpha id
    $scope.get = function () {
        $scope.venue = {};

        Job.get({id: $scope.alpha_id, f: $stateParams.f}).$promise.then(function (rsp) {
            $scope.venue = rsp;

            for (var i = 0; i < $scope.venue.images.length; i++) {
                $scope.venue.images[i]['url'] = $scope.venue.images[i]['src'];
            }
        });

        $scope.readOnly = !$stateParams.f;

        stop = $interval(function () {
            $scope.getPlayers();
        }, 60000);
    };

    $scope.getPlayers = function () {
        Job.screens({venue: $scope.alpha_id}, function (rsp) {
            $scope.venue.screens = rsp.screens;
        });
    };

    // validate serial & get pairing code
    // @deprecated
    $scope.validateSerial = function () {
        if ($scope.player.serial.length < 1) {
            $scope.alert('serialError', 'The serial number must be at least 14 characters');

            return;
        }

        Player.get({serial: $scope.player.serial, venue: $scope.venue.id}).$promise.then(
            function (rsp) {
                console.log(rsp);

                if (rsp.count === 0) {
                    $scope.alert('serialError', 'The serial number is invalid');
                } else if (rsp['error']) {
                    $scope.alert('serialError', rsp['error']);
                } else {
                    // $scope.player.pairingKey = rsp['list'][0]['pairingKey'];

                    $scope.getPlayers();
                }
            }
        );
    };

    // validate serial & get pairing code
    // @deprecated
    $scope.validateMrxSerial = function () {
        if ($scope.player.serial.length < 1) {
            $scope.alert('serialError', 'The serial number must be at least 14 characters');

            return;
        }

        Player.getMrx({serial: $scope.player.serial, venue: $scope.venue.id}).$promise.then(
            function (rsp) {
                console.log(rsp);

                if (rsp.count === 0) {
                    $scope.alert('serialError', 'The serial number is invalid');
                } else if (rsp['isConnected']) {
                    $scope.alert('serialError', 'The serial number has already been paired');
                } else {
                    $scope.getPlayers();
                }
            }
        );
    };

    $scope.parseScreenStatus = function (screen) {
        switch ((screen.status + "").toLowerCase()) {
            case "online":
                return "img/screen-green.png";
            case "offline":

                var ts = Math.round(new Date().getTime() / 1000);
                var tsYesterday = ts - (24 * 3600);

                if (tsYesterday < screen.lastOnline) { //if lastOnline is > 24 hours ago
                    return "img/screen-orange.png";
                }

                return "img/screen-red.png";
            default:
                return "img/screen-red.png";
        }
    };

    $scope.registerScreen = function () {
        if ($scope.player.serial.length > 1 && $scope.player.serial.length < 12) {
            $scope.alert('serialError', 'The serial number must be at least 12 characters');

            return;
        }

        Player.register({}, {serial: $scope.player.serial, venueId: $scope.venue.id, deviceId: $scope.player.deviceId}).$promise.then(
            function (rsp) {
                console.log(rsp);
                if (rsp.error) {
                    $scope.alert('saveError', rsp.error);
                    return
                }
                $scope.player.serial = "";
                $scope.player.deviceId = "";
                $scope.getPlayers();
            }
        );
    };

    // Pairs a screen to a venue
    $scope.saveScreen = function () {
        var data = {sn: $scope.player.serial, deviceId: $scope.player.deviceId};
        Job.end({venue: $scope.venue.id}, data, function (rsp) {
            console.log(rsp);
            if (rsp.errors !== undefined) {
                $scope.alert('saveError', rsp.errors, 10000);
            } else {
                $state.go('success');
            }
        });
    };

    $scope.$watch('venue.screens', function () {
        $scope.showSubmit = false;

        if (!$scope.venue || !$scope.venue.screens) {
            return;
        }

        // $onlineScreens = $filter('filter')($scope.venue.screens, {status: 'ONLINE'});
        $onlineScreens = $filter('filter')($scope.venue.screens, function (screen) {
            return (typeof screen.status === 'string' || screen.status instanceof String)
                && screen.status.toLowerCase() === "online";
        });
        console.log($scope.venue.screens.length, $onlineScreens.length);

        if ($scope.venue.screens.length >= 1 && $onlineScreens.length >= 1) { // TODO: why?
            $scope.showSubmit = true;
        }
    });

    $scope.getDashboardJobs = function () {
        $scope.status = $stateParams.s ? angular.fromJson($stateParams.s) : {
            due: true,
            completed: true,
            in_progress: true,
            failed: true,
            today: false
        };

        Job.getDashboardJobs(function (rsp) {
            $scope.jobs = rsp;

            $scope.filterJobs();

            $scope.jobsPageChanged();
        });
    };

    $scope.filterJobs = function () {
        $scope.jobsCurrentPage = 1;

        $scope.allFilteredJobs = [];

        var alpha = $scope.jobsFilter || "";
        if (alpha.length > 1 && alpha.substr(0, 2) === "VR") {
            if (alpha.substr(0, 3) === "VR0") {
                alpha = alpha.substr(3)
            }
            alpha = alpha.substr(2)
        }
        var $jobsByTextFilter = $filter('filter')($scope.jobs, alpha);

        if ($scope.status.today) {
            var now = new Date();

            //var startOfDay = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
            var startOfDay = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));

            $jobsByTextFilter = $filter('filter')($scope.jobs, {'start_date': startOfDay / 1000});
        }

        for (var i = 0; i < $jobsByTextFilter.length; i++) {
            if ($scope.status.due &&
                (!$jobsByTextFilter[i].completed && !$jobsByTextFilter[i].in_progress && !$jobsByTextFilter[i].failed)) {
                $scope.allFilteredJobs.push($jobsByTextFilter[i]);
            } else if ($jobsByTextFilter[i].completed && $scope.status.completed) {
                $scope.allFilteredJobs.push($jobsByTextFilter[i]);
            } else if ($jobsByTextFilter[i].in_progress && $scope.status.in_progress) {
                $scope.allFilteredJobs.push($jobsByTextFilter[i]);
            } else if ($jobsByTextFilter[i].failed && $scope.status.failed) {
                $scope.allFilteredJobs.push($jobsByTextFilter[i]);
            }
        }

        $scope.jobsPageChanged();
    };

    $scope.jobsPageChanged = function () {
        $scope.filteredJobs = $scope.allFilteredJobs.slice(
            (($scope.jobsCurrentPage - 1) * $scope.itemsPerPage),
            (($scope.jobsCurrentPage) * $scope.itemsPerPage));
    };

    // Utilities
    $scope.openImage = function ($index) {
        Lightbox.openModal($scope.venue.images, $index);
    };

    $scope.index = function () {
        $scope.jobs = Job.query();
    };

    $scope.alert = function (key, value, duration) {
        $scope.alerts[key] = value;
        if (!duration) {
            duration = 5000
        }
        $timeout(function () {
            $scope.alerts[key] = false;

        }, duration)
    };

    $scope.$on('$destroy', function () {
        if (angular.isDefined(stop)) {
            $interval.cancel(stop);
            stop = undefined;
        }
    });

}]);
