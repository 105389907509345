/**
 * Created by sharma on 11/10/2015.
 */

app.factory ('Category', ['$resource', function ($resource)
{
    return $resource('api/category/:id', {},
        {
            query: {
                url: 'api/category',
                isArray: true
            },
            getArticles: {
                url: 'api/category/:id/articles',
                isArray: true
            }
        });

}]);