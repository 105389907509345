/**
 * Created by sharma on 12/22/2015.
 */

app.controller("UlmController", ['$scope', '$state', '$stateParams', '$timeout', 'Ulm', function($scope, $state, $stateParams, $timeout, Ulm)
{
    $scope.options =
    {
        animate   : {
            duration: 0,
            enabled : false
        },
        size      : 110,
        scaleColor: false,
        barColor  : '#6d0019',
        lineWidth : 12,
        lineCap   : 'circle'
    };

    $scope.alerts = {};

    $scope.initCharts = function()
    {
        Ulm.query(function(rsp)
        {
            $scope.rawStats = rsp;

            if(rsp.count_total_grand_total > 0)
            {
                $scope.percent_offline = Math.round(rsp.count_total_offline / rsp.count_total_grand_total * 100);

                $scope.percent_online = Math.round(rsp.count_total_online / rsp.count_total_grand_total * 100);
            }
            else
            {
                $scope.percent_offline = $scope.percent_online = 0;
            }
            if(rsp.count_total > 0)
            {
                $scope.percent_offline_24 = Math.round(rsp.count_total_offline_24 / rsp.count_total * 100);

                $scope.percent_offline_168 = Math.round(rsp.count_total_offline_168 / rsp.count_total * 100);
            }
            else
            {
                $scope.percent_offline_24 = $scope.percent_offline_168 = 0;
            }
        });
    };

    $scope.searchByPostcode = function()
    {
        if(!$scope.postcode)
        {
            $scope.alert('error', 'You must enter a postcode or a 14 chars serial to search');

            return;
        }

        $scope.search = $scope.postcode;

        Ulm.get({postcode: $scope.postcode}, function(rsp)
        {
            $scope.screens = rsp;

            if($scope.screens.length === 0)
            {
                $scope.alert('errorPostCode', 'No screen found for the postcode.');
            }
        });
    };

    $scope.searchBySerial = function ()
    {
        if(!$scope.serial || $scope.serial.length < 14)
        {
            $scope.alert('error', 'You must enter a postcode or a serial to search');

            return;
        }

        $scope.search = $scope.serial;

        Ulm.get({sn: $scope.serial}, function(rsp)
        {
            $scope.screens = rsp;

            if($scope.screens.length === 0)
            {
                $scope.alert('errorSn', 'No screen found for the serial number.');
            }
        });
    };

    $scope.alert = function(key, value)
    {
        $scope.alerts[key] = value;

        $timeout(function()
        {
            $scope.alerts[key] = false;

        }, 5000)
    }

}]);