/**
 * Created by sharma on 11/22/2015.
 */

app.factory('Stat', ['$resource', function($resource)
{
    return $resource('api/stat', {}, {
        query   : {isArray: false},
        scopeFor: {url: 'api/stat/for/:day/:ref', isArray: true, method: 'GET'}
    });
}]);