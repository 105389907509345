/**
 * Created by sharma on 11/5/2015.
 */
app.factory ('Job', ['$resource', function ($resource)
{
    return $resource('api/job/:id', {},
        {
            authorize: {
                url: 'api/job/authorize',
                method: 'POST'
            },
            validatePostcode: {
                url: 'api/job/validate-postcode',
                method: 'POST'
            },
            getCurrent: {
                url: 'api/job/current',
                method: 'GET',
                isArray: true
            },
            getDashboardJobs: {
                url: 'api/job/dashboard',
                method: 'GET',
                isArray: true
            },
            screens: {
                url: 'api/job/:venue/screens',
                method: 'GET',
                isArray: false
            },
            end: {
                url: 'api/job/:venue/end',
                method: 'PUT'
            }
        });

}]);