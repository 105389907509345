/**
 * Created by sharma on 11/10/2015.
 */

app.controller ("ArticleController", ['$scope', '$timeout', '$uibModal', '$state', '$stateParams', 'Article', 'Category', function ($scope, $timeout, $uibModal, $state, $stateParams, Article, Category)
{
    $scope.forms = {};

    $scope.alerts = {success: false, errorCat: [], errorArticle: []};

    $scope.taOptions = [
        ['h1', 'h2', 'h3', 'p'],
        ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
        ['html', 'wordcount', 'charcount']
    ];

    $scope.itemsPerPage = 5;
    $scope.categoriesCurrentPage = 1;
    $scope.articlesCurrentPage = 1;

    $scope.init = function ()
    {
        $scope.loadCategories ();

        $scope.loadArticles ();

        $scope.loadArticle ();
    };

    $scope.loadCategories = function ()
    {
        Category.query ().$promise.then(function (rsp)
        {
            $scope.categories = rsp;
            if ($stateParams.cPage)
            {
                $scope.categoriesCurrentPage = $stateParams.cPage;
            }
            $scope.categoriesPageChanged();
        });
    };

    $scope.categoriesPageChanged = function ()
    {
        $scope.filteredCategories = $scope.categories.slice (
            ( ($scope.categoriesCurrentPage - 1) * $scope.itemsPerPage ),
            ( ( $scope.categoriesCurrentPage ) * $scope.itemsPerPage ));
    };

    $scope.loadArticles = function ()
    {
        if ($stateParams.category)
        {
            $scope.category = $stateParams.category;

            Category.getArticles ({id: $stateParams.category}).$promise.then (function (rsp)
            {
                $scope.articles = rsp;

                if ($stateParams.aPage)
                {
                    $scope.articlesCurrentPage = $stateParams.aPage;
                }

                $scope.articlesPageChanged();
            })
        }
    };

    $scope.articlesPageChanged = function ()
    {
        $scope.filteredArticles = $scope.articles.slice (
            ( ($scope.articlesCurrentPage - 1) * $scope.itemsPerPage ),
            ( ( $scope.articlesCurrentPage ) * $scope.itemsPerPage ));
    };

    $scope.loadArticle = function ()
    {
        if ($stateParams.article)
        {
            Article.get ({id: $stateParams.article}).$promise.then (function (rsp)
            {
                $scope.dArticle = rsp;
            })
        }
    };

    /** CRUD Category **/
    $scope.storeCategory = function ()
    {
        $scope.category = {};

        $scope.open ('frm-category.html');
    };

    $scope.updateCategory = function (catObj)
    {
        $scope.category = angular.copy (catObj);

        $scope.open ('frm-category.html');
    };

    $scope.submitCategory = function ()
    {
        if ($scope.forms.frmCategory.$valid)
        {
            var $request = Category.save ({id: $scope.category.id ? $scope.category.id : null}, $scope.category);

            $request.$promise.then (function (rsp)
            {
                $scope.loadCategories ();

                $scope.close ();

                $scope.alert ('success', 'Category ' + rsp.name + ' saved successfully');

            }, function (rsp)
            {
                $scope.alert ('errorCat', rsp.data);
            });
        }
    };

    $scope.deleteCategory = function (id)
    {
        if (!window.confirm ('Do you want to delete this category?'))
        {
            return;
        }

        Category.delete ({id: id}).$promise.then (function (rsp)
        {
            $scope.alert ('success', 'Category deleted successfully');

            $scope.loadCategories ();
        });
    };
    /** End CRUD Category **/

    /** CRUD Article **/
    $scope.storeArticle = function ()
    {
        $scope.article = {category_id: parseInt($scope.category)};

        $scope.open ('frm-article.html', 'lg');
    };

    $scope.updateArticle = function (articleObj)
    {
        $scope.article = angular.copy (articleObj);

        $scope.open ('frm-article.html', 'lg');
    };

    $scope.submitArticle = function ()
    {
        if ($scope.forms.frmArticle.$valid)
        {
            var $request = Article.save ({id: $scope.article.id ? $scope.article.id : null}, $scope.article);

            $request.$promise.then (function (rsp)
            {
                $scope.loadArticles ();

                $scope.loadArticle ();

                $scope.close ();

                $scope.alert ('success', 'Article ' + rsp.title + ' saved successfully');

            }, function (rsp)
            {
                $scope.alert ('errorArticle', rsp.data);
            });
        }
    };

    $scope.deleteArticle = function (id)
    {
        if (!window.confirm ('Do you want to delete this article?'))
        {
            return;
        }

        Article.delete ({id: id}).$promise.then (function (rsp)
        {
            $scope.alert ('success', 'Article deleted successfully');

            $state.go ('admin.troubleShooterCategory', {category: $scope.category});
        });
    };

    var timer = false;

    $scope.$watch ('searchArticles', function ()
    {
        if (timer)
        {
            $timeout.cancel (timer)
        }
        timer = $timeout(function ()
        {
            if ($scope.searchArticles && $scope.searchArticles.length > 0)
            {
                Article.query ({'search': $scope.searchArticles}).$promise.then (function (rsp)
                {
                    $scope.articles = rsp;

                    $scope.articlesPageChanged();
                });
            }
        }, 500)
    });
    /** End CRUD Article **/

    /** Modal Utilities **/
    $scope.open = function (id, size)
    {
        if (!size)
        {
            size = '';
        }
        $scope.modalInstance = $uibModal.open ({
            animation: $scope.animationsEnabled,
            templateUrl: id,
            scope: $scope,
            keyboard: false,
            backdrop: 'static',
            size: size,
            resolve: {
                items: function ()
                {
                    return $scope.items;
                }
            }
        });
    };

    $scope.close = function ()
    {
        $scope.modalInstance.close ();
    };

    $scope.alert = function (key, value)
    {
        $scope.alerts[key] = value;

        $timeout(function ()
        {
            $scope.alerts[key] = false;

        }, 5000)
    }

}]);