/**
 * Created by sharma on 11/30/2015.
 */

app.controller ("JobInfoController", ['$scope', '$state', '$filter', '$stateParams', 'Job', function ($scope, $state, $filter, $stateParams, Job)
{
    $scope.getCurrentJobs = function ()
    {
        Job.getCurrent().$promise.then (function (rsp)
        {
            $scope.allJobs = $scope.currentJobs = rsp;
        });
    };

    $scope.filterJobs = function ()
    {
        $scope.currentJobs = $filter('filter')($scope.allJobs, $scope.jobsFilter);
    };
}]);