/**
 * Created by sharma on 11/6/2015.
 */

app.factory ('Article', ['$resource', function ($resource)
{
    return $resource('api/article/:id', {},
        {
            query: {
                url: 'api/article',
                isArray: true
            }
        });

}]);