/**
 * Created by sharma on 11/26/2015.
 */

app.controller ("PlayerController", ['$scope', '$state', '$stateParams', '$timeout', 'Player', function ($scope, $state, $stateParams, $timeout, Player)
{
    $scope.screensPerPage = 12;

    $scope.alerts = {};

    $scope.status = {online: true, offline: true};

    $scope.options = {
        animate: {
            duration: 0,
            enabled: false
        },
        size: 110,
        scaleColor: false,
        barColor: '#6d0019',
        lineWidth: 12,
        lineCap: 'circle'
    };

    $scope.get = function ()
    {
        var $params = {};

        if ($stateParams.p)
        {
            $params.page = $stateParams.p;
        }

        if ($stateParams.s)
        {
            $params.search = $stateParams.s;
        }

        if ($stateParams.o)
        {
            $scope.status = JSON.parse($stateParams.o);
        }

        $params.status = $scope.status;

        Player.query ($params).$promise.then (function (rsp)
        {
            $scope.players = rsp.list;
            $scope.count = rsp.count;
            $scope.count_total_online = Math.round(rsp.count_total_online / rsp.count_total * 100);
            $scope.count_total_offline = Math.round(rsp.count_total_offline / rsp.count_total * 100);
            $scope.screensCurrentPage = $stateParams.p;
            $scope.searchText = $stateParams.s;
        });
    };

    $scope.getScreens = function (page)
    {
        var $params = {
            p: (page)?page:1,
            s: $scope.searchText,
            o: JSON.stringify($scope.status)
        };

        $state.go ('admin.screenStatus', $params, {reload: true});
    };

    $scope.alert = function (key, value)
    {
        $scope.alerts[key] = value;

        $timeout(function ()
        {
            $scope.alerts[key] = false;

        }, 5000)
    };

    $scope.get ();


}]);