/**
 * Created by sharma on 1/7/2016.
 */

app.factory('Auth', ['$resource', function($resource)
{
    return $resource(null, null,
        {
            sendResetPasswordLink: {url: 'api/auth/password/email', method: 'POST'},
            resetPassword        : {url: 'api/auth/password/reset', method: 'POST'}
        });

}]);