/**
 * Created by sharma on 11/4/2015.
 */

var app = angular.module('app', ['ui.router', 'ngAnimate', 'satellizer', 'blockUI', 'ngResource', 'ui.bootstrap', 'textAngular', 'slick', 'bootstrapLightbox', 'easypiechart']);

app.config(function($stateProvider, $urlRouterProvider, $authProvider)
{
    /** Client routes **/
    $stateProvider
        .state('index', {
            url        : '/',
            templateUrl: 'views/home.tpl.html',
            controller : 'JobController'
        })
        .state('postcode', {
            url        : '/venue/{alpha_id}',
            templateUrl: 'views/postcode.tpl.html',
            controller : 'JobController'
        })
        .state('job', {
            url        : '/venue/{alpha_id}/postcode/{postcode}?f',
            templateUrl: 'views/job-overview.tpl.html',
            controller : 'JobController'
        })
        .state('success', {
            url        : '/success',
            templateUrl: 'views/success.tpl.html',
            controller : 'JobController'
        })
        .state('troubleShooter', {
            url        : '/troubleshooter',
            templateUrl: 'views/troubleshooter.tpl.html',
            controller : 'ArticleController'
        })
        .state('troubleShooterCategory', {
            url        : '/troubleshooter/category/{category}?cPage',
            templateUrl: 'views/troubleshooter.tpl.html',
            controller : 'ArticleController'
        })
        .state('troubleShooterArticle', {
            url        : '/troubleshooter/category/{category}/article/{article}?cPage&aPage',
            templateUrl: 'views/troubleshooter.tpl.html',
            controller : 'ArticleController'
        });

    /** Authentication routes **/
    $stateProvider
        .state('auth', {
            abstract  : true,
            url       : '/auth',
            template  : '<div class="ui-view-container"><ui-view /></div>',
            controller: 'AuthController'
        })
        .state('auth.login', {
            url        : '/login',
            templateUrl: 'views/auth/login.tpl.html',
            resolve    : {
                loginNotRequired: ['$q', '$state', '$auth', function($q, $state, $auth)
                {
                    var deferred = $q.defer();

                    if($auth.isAuthenticated())
                    {
                        deferred.reject();
                    }
                    else
                    {
                        deferred.resolve();
                    }
                    return deferred.promise;
                }]
            }
        })
        .state('auth.password', {
            url        : '/password',
            templateUrl: 'views/auth/password.tpl.html',
            controller : 'AuthController'
        })
        .state('auth.reset', {
            url        : '/reset/:token',
            templateUrl: 'views/auth/reset.tpl.html',
            controller : 'AuthController'
        });

    /** Admin routes **/
    $stateProvider
        .state('admin', {
            abstract   : true,
            url        : '/admin',
            templateUrl: 'views/admin/admin.tpl.html',
            resolve    : {
                loginRequired: ['$q', '$location', '$auth', function($q, $location, $auth)
                {
                    var deferred = $q.defer();

                    if($auth.isAuthenticated())
                    {
                        deferred.resolve();
                    }
                    else
                    {
                        $location.path('/auth/login');
                    }
                    return deferred.promise;
                }]
            }
        })
        .state('admin.dashboard', {
            url        : '/dashboard?s',
            templateUrl: 'views/admin/dashboard.tpl.html',
            controller : 'JobController'
        })
        .state('admin.jobInfo', {
            url        : '/job-info',
            templateUrl: 'views/admin/job-info.tpl.html',
            controller : 'JobInfoController'
        })
        .state('admin.ulm', {
            url        : '/ulm',
            templateUrl: 'views/admin/ulm.tpl.html',
            controller : 'UlmController'
        })
        .state('admin.stats', {
            url        : '/stats',
            templateUrl: 'views/admin/stats.tpl.html',
            controller : 'StatController'
        })
        .state('admin.completed', {
            url        : '/completed/{day}/{ref}',
            templateUrl: 'views/admin/completed.tpl.html',
            controller : 'StatController'
        })
        .state('admin.troubleShooter', {
            url        : '/troubleshooter',
            templateUrl: 'views/admin/troubleshooter.tpl.html',
            controller : 'ArticleController'
        })
        .state('admin.troubleShooterCategory', {
            url        : '/troubleshooter/category/{category}?cPage',
            templateUrl: 'views/admin/troubleshooter.tpl.html',
            controller : 'ArticleController'
        })
        .state('admin.troubleShooterArticle', {
            url        : '/troubleshooter/category/{category}/article/{article}?cPage&aPage',
            templateUrl: 'views/admin/troubleshooter.tpl.html',
            controller : 'ArticleController'
        })
        .state('admin.screenStatus', {
            url        : '/screen-status?p&s&o',
            templateUrl: 'views/admin/screen-status.tpl.html',
            controller : 'PlayerController'
        });

    /** Redirection **/
    $urlRouterProvider
        .when('/admin', '/admin/dashboard')

        .otherwise('/');

    /** Satellizer configuration **/
    $authProvider.loginUrl = window.location.origin.replace("http:", "").replace("https:", "") + '/api/auth';
    // $authProvider.loginUrl = '//' + window.location.hostname + window.location.pathname + 'api/auth';

}).config(function(blockUIConfig)
{
    // Change the default delay to 100ms before the blocking is visible
    blockUIConfig.delay = 0;

}).config(function(uibPaginationConfig)
{
    uibPaginationConfig.previousText  = '<';
    uibPaginationConfig.nextText      = '>';
    uibPaginationConfig.firstText     = '<<';
    uibPaginationConfig.lastText      = '>>';
    uibPaginationConfig.maxSize       = 3;
    uibPaginationConfig.boundaryLinks = true;
}).config(function($httpProvider)
{
    $httpProvider.interceptors.push(function($q, $location)
    {
        return ({
            responseError: function(rejection)
            {
                if(rejection.data.error === 'token_not_provided')
                {
                    $location.path('/auth/login');
                }
                else
                {
                    return $q.reject(rejection);
                }
            }
        });
    });
});